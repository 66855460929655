<template>
    <div class="login">
       <div class="login-bg" :style="{backgroundImage:'url('+backgroundImg.img+')'}"></div>
      <div class="loginR">
        <div class='loginsTop'>
          <img
              class="logo"
              src="../../assets/images/logo.png"
           alt="logo"/>
          <p class="text" >通贩后台登陆</p>
        </div>
        <div class="loginsMain" v-if="!forgetPwdFlag">
          <!-- 登录 -->
          <div class="loginGo" v-if="stateType === 1">
              <!-- 密码登录 -->
            <template v-if="loginState">
              <el-form :model="accountPwdLogin"  ref="ruleLoginPwdForm" :rules="rules">
                <el-form-item prop="user">
                  <el-input
                      placeholder="ID/手机号/邮箱"
                      prefix-icon="el-icon-user"
                      v-model.trim="accountPwdLogin.user"
                  >
                  </el-input>
                </el-form-item>
                <el-form-item prop="password">
                  <el-input
                      placeholder="密码"
                      prefix-icon="el-icon-lock"
                      v-model="accountPwdLogin.password"
                      show-password
                  >
                  </el-input>
                </el-form-item>
                <el-form-item>
                  <el-button  @click="judgeFn" @keyup.enter="judgeFn">登录</el-button>
                </el-form-item>
              </el-form>
            </template>
            <!-- 验证码登录 -->
            <template v-if="!loginState">
              <PhoneLogin></PhoneLogin>
            </template>

              <div class="login-options">
                <p
                    @click="loginState=!loginState"
                    class="font hand"
                    v-text="loginState ? '验证码登录' : '密码登录'"
                ></p>
                <p @click="goRuzhu" class="hand">
                  申请入驻
                </p>
              </div>


          </div>
        </div>
        <div class="loginsMain" v-else>
          <Forgetpassword @changeForget="changeForget"></Forgetpassword>
        </div>
      </div>
    </div>
</template>

<script>
import {pwdLogin} from "@/api/Login";
import PhoneLogin from "@/components/Login/phoneLogin.vue";
import Forgetpassword from "@/views/Login/Forgetpassword.vue";
//正则验证
import { rules, rulesreg,rules1 } from '@/utils/verify/rules'
    export default {
        name: "LoginPage",
      components:{
        PhoneLogin,
        Forgetpassword
      },
      computed: {
        rules() {
          return rules
        }
      },
        data(){
            return {
              backgroundImg:{
                img:"https://images.chuoooo.com/paint/20221029/b1d4a7b695daf347904571d14180862f.png"
              },
              stateType:1,
              loginState:true,
              //密码登陆
              accountPwdLogin:{
                user:"",
                password:""
              },
              //忘记密码
              forgetPwdFlag:false


            }
        },
        created(){
          document.onkeyup=e=>{
            if (e.keyCode === 13){
              this.judgeFn()
            }
          }
        },
        mounted(){
          // this.getBg();
        },
        methods:{
          async getBg(){
            let res = await getLoginBg();
            let num = Math.round(Math.random()*(res.length-1))
            this.backgroundImg= res[num]
            console.log(this.backgroundImg,"背景")
          },
          changeForget(){
            this.forgetPwdFlag=!this.forgetPwdFlag
          },
          judgeFn(){
            this.$refs.ruleLoginPwdForm.validate(valid=>{
              if (!valid)return
              this.loginFn()
            })
          },
          async loginFn(){
            console.log(this.accountPwdLogin,"账号密码")
             let res = await pwdLogin(this.accountPwdLogin)
            console.log(res,"***********")
            // if (res.code !==1) return this.$message.error(res.msg)

            // if (res.tongfanstate === 0) return this.$message.error("未认证开通橱窗，请前往通贩进行开通")
            // if (res.tongfanstate === 1) return this.$message.error("正在审核中~")
            // localStorage.setItem('id',res.id);
            if (!res.tongfanstate && res.tongfanstate !== 0) return this.$message.error(res.msg)
            localStorage.setItem('token',res.token);
            localStorage.setItem('tongfanstate',res.tongfanstate);
            this.$message.success("登陆成功")
            this.$router.push("/home/salesPro")
            this.$store.dispatch('user/getUserInfo')


          },
          goRuzhu(){
            window.open('https://buy.chuoooo.com/applyShop',"_blank")
          },
        }

    }
</script>

<style scoped lang="scss">

// 发送验证码
.code {
  width: 94px ;
  padding: 0px ;
  background: rgba(220, 220, 242, 0) ;
  color: #b5b4cc;
}
::v-deep.el-input {
  width: 260px;
  height: 44px;
}

::v-deep.el-button {
  width: 94px;
  padding: 0;
  border: none;
  font-family: 'PingFang HK';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #b5b4cc;
}
::v-deep .el-input__inner{
  border-radius: 12px;
  height: 44px;
  line-height: 44px;
  background-color: rgba(220,220,242,.4);
  border: none;
  padding-left: 40px;
}
::v-deep .el-input{
  font-size: 15px;
}
::v-deep .el-input__icon{
  line-height: 44px;
}
::v-deep .el-input__prefix{
  font-size: 20px;
  color: #242433;
}
::v-deep .el-form-item__error{
  left: 40%;
}
::v-deep .is-error .el-form-item__content .el-input{
  border: 1px solid #fe1d5f;
  border-radius: 12px;
}
.login {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  .login-bg{
    width: 100%;
    height: 100%;
    position: fixed;
    background-position: right;
  }
  .loginR {
    position: relative;
    width: 500px;
    border-radius:30px;
    background-color: #fff;
    opacity: 0.95;
    min-height: 500px;
    .loginsTop {
      text-align: center;
      .logo {
        width: 100px;
        height: 100px;
      }

      .text {
        font-family: 'PingFang HK';
        font-style: normal;
        font-weight: 600;
        font-size: 26px;
        color: #3b36d2;
        margin-bottom: 20px;
      }
    }
    .loginsMain {
      text-align: center;
      //padding-top: 200px;

      .title {
        display: flex;
        align-items: center;
        justify-content: center;

        p {
          font-family: 'PingFang HK';
          font-style: normal;
          font-weight: 500;
          font-size: 24px;
          line-height: 34px;
          color: #535266;
        }

        span {
          display: block;
          width: 2px;
          height: 16px;
          background: #dcdcf2;
          margin: 0px 40px;
        }
        .login-font {
          font-weight: 600 ;
          font-size: 24px ;
          line-height: 34px ;
          color: #242433 ;
        }
      }

      .loginGo {
        margin-top: 40px;

        .el-form {
          .el-button {
            width: 260px;
            height: 44px;
            background: #6357ff;
            border-radius: 12px;
            font-family: 'PingFang HK';
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            color: #ffffff;
          }
        }

        .login-options {
          width: 260px;
          margin: 20px auto;
          display: flex;
          align-items: center;
          justify-content: space-between;

          p {
            font-family: 'PingFang HK';
            font-style: normal;
            font-weight: 500;
            font-size: 13px;
            line-height: 18px;
            color: #535266;
            padding: 10px;
          }
        }

        .login-other {
          margin-top: 40px;

          P {
            font-weight: 500;
            font-size: 12px;
            line-height: 18px;
            color: #b5b4cc;
            margin-bottom: 14px;
          }

          .login-other-main {
            img {
              width: 24px;
              height: 24px;
              padding: 12px;
            }
          }
        }
      }
    }
  }
}

</style>
