<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script>
export default {


  data() {
    return {}
  },
  created() {
    this.$store.dispatch('user/getUserInfo')
  },
  methods: {},

}
</script>
<style lang="scss">

.w-base{
  width: 70%;
}
.color-base{
  color: #6357ff;
}
.main-btn{
  width: 90px;
  height: 40px;
  border-radius: 12px;
  text-align: center;
  line-height: 40px;
  color: #fff;
  background-color: #6357FF;
}
.bottom-line{
  border-bottom: 1px solid #F1F1FA;
  margin-bottom: 15px;
}


</style>
