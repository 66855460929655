import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/Home/index.vue';
import LoginView from "@/views/Login/index.vue"
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect:"/login"
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView
  },
  {
    path: '/home',
    component: HomeView,
    children:[
      {
        path: "",
        component:()=>import("@/views/Home/Home.vue")
      },
      {
        path:"salesPro",
        component:()=>import("@/views/Home/SalesPro/index.vue")//设图
      },
      {
        path:"editoc/:id",
        name:"editoc",
        component:()=>import("@/views/Home/editInfo/editoc.vue")//设图修改
      },
      {
        path:"saleVirtually",
        component:()=>import("@/views/Home/SaleVirtually/index.vue")//虚拟形象
      },
      {
        path:"saleMaterial",
        component:()=>import("@/views/Home/SaleMaterial/index.vue")//素材
      },
      {
        path:"editMater/:id",
        name:"editMater",
        component:()=>import("@/views/Home/editInfo/editMater.vue")//素材修改
      },
      {
        path:"saleMaterialList",
        component:()=>import("@/views/Home/SaleMaterial/List.vue")//素材列表
      },
      {
        path: "saleMobileCapture",
        component:()=>import("@/views/Home/SaleMobileCapture/index.vue")//动捕设备
      },
      {
        path:"saleList",
        component:()=>import("@/views/Home/SaleList/index.vue")//设图列表
      },
      {
        path:"account",
        component:()=>import("@/views/Home/Account/index.vue")//账户
      },
      {
        path:"order",
        component:()=>import("@/views/Home/Order/index.vue")//账户
      },
      {
        path:"bidding",
        component:()=>import("@/views/Home/agreement/agreeBidding.vue")//竞价协议
      },
      {
        path:"agreepdd",
        component:()=>import("@/views/Home/agreement/agreePdd.vue")//pdd协议
      },
      {
        path:"agreeSellMater",
        component:()=>import("@/views/Home/agreement/sellMater.vue")//素材出售协议
      },
      {
        path:"agreeoc",
        component:()=>import("@/views/Home/agreement/agreeoc.vue")//美术作品交易服务协议
      },
      {
        path:"agreeSell",
        component:()=>import("@/views/Home/agreement/aggreeSell.vue")//美术作品出售规则协议
      },
      {
        path:"agreeWithdrawal",
        component:()=>import("@/views/Home/agreement/agreeWithdrawal.vue")//提现服务协议
      },
      {
        path:"agreeRecharge",
        component:()=>import("@/views/Home/agreement/agreeRecharge.vue")//充值协议
      },
      {
        path: 'sheetSiteSet',
        component:()=>import("@/views/Home/sheetSite/sheetSiteSet.vue")// 稿位设置
      },
      {
        path: 'sheetSiteList',
        component:()=>import("@/views/Home/sheetSite/sheetSiteList.vue")// 稿位列表
      },
      {
        path: 'editSheetSite/:id',
        name: 'editSheetSite',
        component: ()=> import('@/views/Home/editInfo/editSheetSite.vue') // 稿位修改
      },
      {
        path: 'agreePublic',
        component:()=>import("@/views/Home/agreement/agreePublic.vue") // 稿位发布协议
      }

    ]
  },
  {
    path: "*",
    redirect: '/login'
  }

]

const router = new VueRouter({
  mode: 'hash',
  // base: process.env.BASE_URL,
  routes
})


export default router
