import Vue from 'vue'
import Vuex from 'vuex'
import getters from './getters'
Vue.use(Vuex)

const modules = {}
const files = require.context('./modules', false, /\.js$/)
files.keys().forEach((key) => {
  modules[key.replace(/(modules|\/|\.|js)/g, '')] = {
    ...files(key).default,
    namespaced: true,
  }
})

export default new Vuex.Store({
  state: {
    BaseImgUrl:'https://images.chuoooo.cn/',
    // tongfanUrl:'https://woc.chuoooo.com/',
    tongfanUrl:'https://chuobuy.com/',
    buttonLoading:false,
    pageLoading:false
  },
  getters,
  mutations: {
    BASEIMGURL(state,item){
      state.BaseImgUrl=item
    },
    SET_BUTLAODING(state,bool){
      state.buttonLoading=bool
    },
    SET_PAGLOADING(state,bool){
      state.pageLoading=bool
    }
  },
  actions: {
  },
  modules
})
