<template>
  <div class="forgetPwd">
    <p class="topText">请设置您的新密码，设置后立即生效。</p>
    <el-form :model="forgetPwdForm"  ref="ruleLoginPwdForm" :rules="rulesforget" >
      <el-form-item prop="phone">
        <el-input
            placeholder="手机号"
            v-model.trim="forgetPwdForm.phone"
        >
        </el-input>
      </el-form-item>
      <el-form-item prop="code" class="getCode">
        <el-input
            placeholder="请输入验证码"
            v-model="forgetPwdForm.code"
        > <el-button slot="suffix" :disabled="disabled" @click="getCode">
          <span >{{codeText}}</span>
        </el-button>

        </el-input>
      </el-form-item>
      <el-form-item prop="password">
        <el-input
            placeholder="密码"
            v-model="forgetPwdForm.password"
            show-password
        >
        </el-input>
      </el-form-item>
      <el-form-item prop="againpwd">
        <el-input
            placeholder="再次输入验证码"
            v-model="forgetPwdForm.againpwd"
            show-password
        >
        </el-input>
      </el-form-item>
      <el-form-item class="loogin-button">
        <el-button >重置密码</el-button>
      </el-form-item>
    </el-form>
    <p class="backLogin margin-big-bottom hand" @click="$emit('changeForget')">返回登陆</p>
  </div>
</template>

<script>
import {rulesforget} from "@/utils/verify/rules"
export default {
  name: "forgetpassword",
  data() {
    var password2=(rule, value, callback)=> {
      if (value === '') {
        callback(new Error('请再次输入密码'))
      } else if (value !== this?.forgetPwdForm?.password) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    }
    return {
      forgetPwdForm:{
        phone:"",
        code:"",
        password:"",
        againpwd:""
      },
      codeText:"获取验证码",
      time:60,
      disabled:false,
      rulesforget:{
      ...rulesforget,
        againpwd: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          { trigger: 'blur', validator: password2 }
        ]
      }
    }
  },
  methods: {
    getCode(){
      if (this.time > 0){
        this.time--
        this.codeText=this.time + 's秒后重新获取';
        setTimeout(this.getCode,1000)
      }else {
        this.time=0;
        this.codeText='获取验证码';
        this.disabled=false
      }
    }
  }

}
</script>

<style scoped lang="scss">
.topText{
  color: #242433;
  font-size: 13px;
  font-weight: 600;
  line-height: 18px;

}
::v-deep.el-input {
  width: 260px;
  height: 44px;
}

::v-deep.el-button {
  width: 94px;
  padding: 0;
  border: none;
  font-family: 'PingFang HK';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #b5b4cc;
}
.forgetPwd ::v-deep .el-input__inner{
  border-radius: 12px;
  height: 44px;
  line-height: 44px;
  background-color: rgba(220,220,242,.4);
  border: none;
  padding-left: 15px;
}
::v-deep .el-input{
  font-size: 15px;
}
::v-deep .el-input__icon{
  line-height: 44px;
}
::v-deep .el-input__prefix{
  font-size: 20px;
  color: #242433;
}
::v-deep .el-form-item__error{
  left: 40%;
}
::v-deep .is-error .el-form-item__content .el-input{
  border: 1px solid #fe1d5f;
  border-radius: 12px;
}
.el-form {
  .loogin-button .el-button {
    width: 260px;
    height: 44px;
    background: #6357ff;
    border-radius: 12px;
    font-family: 'PingFang HK';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #ffffff;
  }
  .getCode .el-button {
    border: 0;
    background-color: transparent;
    height: 100%;
    width: auto;
  }

}
.backLogin{
  color: #6357ff;
  font-size: 13px;
  line-height: 18px;
  font-weight: 600;
}
</style>
