// 验证用户名
// function username(rule, value, callback) {
//     // 验证手机号和邮箱
//     var passwordreg = /(^[\w.\-]+@(?:[a-z0-9]+(?:-[a-z0-9]+)*\.)+[a-z]{2,3}$)|(^1[3|4|5|8]\d{9}$)/
//     if (!passwordreg.test(value)) {
//         callback(new Error('账号格式有误'))
//     } else {
//         callback()
//     }
// }

// 验证用户名
function username(rule, value, callback) {
  // 验证手机号和邮箱

  var passwordreg =
    /(^[\w.\-]+@(?:[a-z0-9]+(?:-[a-z0-9]+)*\.)+[a-z]{2,3}$)|(^1[3|4|9|5|6|7|8]\d{9}$)/

  if (!passwordreg.test(value)) {
    callback(new Error('手机号格式有误'))
  } else {
    callback()
  }
}

function email(rule, value, callback) {
  // 验证手机号和邮箱
  var passwordreg = /(^[\w.\-]+@(?:[a-z0-9]+(?:-[a-z0-9]+)*\.)+[a-z]{2,3}$)/
  if (!passwordreg.test(value)) {
    callback(new Error('账号格式有误'))
  } else {
    callback()
  }
}

function userlogin(rule, value, callback) {
  // 验证手机号和邮箱
  var passwordreg =
    /(^[\w.\-]+@(?:[a-z0-9]+(?:-[a-z0-9]+)*\.)+[a-z]{2,3}$)|(^1[3|4|5|7|8]\d{9}$)|(^\w{4,20}$)/
  if (!passwordreg.test(value)) {
    callback(new Error('账号格式有误'))
  } else {
    callback()
  }
}
// 昵称
function name(rule, value, callback) {
  // var passwordreg = /[\u4e00-\u9fa5]|[\u0800-\u4e00]{3,16}$|(^[\w.\-]+@(?:[a-z0-9]+(?:-[a-z0-9]+)*\.)+[a-z]{2,3}$)|^\w{3,16}$/
  console.log(value.length)
  if (value.length > 10) {
    callback(new Error('昵称超出限制'))
  } else {
    var passwordreg =
      /[\u4E00-\u9FA5]{1,10}$|[A-Za-z]{1,10}$|[0-9]{1,10}$|[\u0800-\u4e00]{1,10}$/
    // var passwordreg = /^[\u4e00-\u9fa5]{1,7}$|^[\dA-Za-z_]{1,14}$/

    if (!passwordreg.test(value)) {
      callback(new Error('可支持使用中文、英文、日文'))
    } else {
      callback()
    }
  }
}
// 密码
function password(rule, value, callback) {
  let passwordreg = /^[^ $ #]{3,16}$/
  if (!passwordreg.test(value)) {
    callback(new Error('密码格式错误'))
  } else {
    callback()
  }
}
//支付密码正则
function PayPassword(rule, value, callback) {
  var passwordreg = /^\d{6}$/
  if (!passwordreg.test(value)) {
    callback(new Error('密码格式错误'))
  } else {
    callback()
  }
}
// 验证两次密码是否相同
function password2(rule, value, callback) {
  if (value === '') {
    callback(new Error('请再次输入密码'))
  } else if (value !== this?.forgetPwdForm?.password) {
    callback(new Error('两次输入密码不一致!'))
  } else {
    callback()
  }
}
// 验证码校验
function code(rule, value, callback) {
  var passwordreg = /^\w{4}$/
  if (!passwordreg.test(value)) {
    callback(new Error('验证码格式错误'))
  } else {
    callback()
  }
}
// 登录校验
export const rules = {
  user: [
    { required: true, message: '请输入账号', trigger: 'blur' },
    { trigger: 'blur', validator: userlogin }
  ],
  password: [
    { required: true, message: '请输入密码', trigger: 'blur' },
    { trigger: 'blur', validator: password }
  ]
}
// 验证码登录校验
export const rules1 = {
  phone: [
    { required: true, message: '请输入账号', trigger: 'blur' },
    { trigger: 'blur', validator: username }
  ],
  code: [
    { required: true, message: '请输入验证码', trigger: 'blur' },
    { trigger: 'blur', validator: code }
  ]
}
// 注册 校验
export const rulesreg = {
  user: [
    { required: true, message: '请输入昵称', trigger: 'blur' },
    { trigger: 'blur', validator: name }
  ],
  password: [
    { required: true, message: '请输入密码', trigger: 'blur' },
    { trigger: 'blur', validator: password }
  ],
  tel: [
    { required: true, message: '请输入账号', trigger: 'blur' },
    { trigger: 'blur', validator: username }
  ],
  code: [
    { required: true, message: '请输入验证码', trigger: 'blur' },
    { trigger: 'blur', validator: code }
  ]
}
//邮箱更改
export const EmailRulesreg = {
  email: [
    { required: true, message: '请输入账号', trigger: 'blur' },
    { trigger: 'blur', validator: email }
  ],
  code: [
    { required: true, message: '请输入验证码', trigger: 'blur' },
    { trigger: 'blur', validator: code }
  ]
}

//个人中心的支付管理正则验证
export const PayRulesreg = {
  tel: [
    { required: true, message: '请输入账号', trigger: 'blur' },
    { trigger: 'blur', validator: username }
  ],
  password: [
    { required: true, message: '请输入密码', trigger: 'blur' },
    { trigger: 'blur', validator: PayPassword }
  ],
  code: [
    { required: true, message: '请输入验证码', trigger: 'blur' },
    { trigger: 'blur', validator: code }
  ]
}
//绑定手机号
export const binding = {
  tel: [
    { required: false, message: '请输入手机号', trigger: 'blur' },
    { trigger: 'blur', validator: username }
  ],
  password: [
    { required: false, message: '请输入密码', trigger: 'blur' },
    { trigger: 'blur', validator: PayPassword }
  ],
  code: [
    { required: false, message: '请输入验证码', trigger: 'blur' },
    { trigger: 'blur', validator: code }
  ]
}

//个人中心更改借用
export const phoneRules = {
  code: [
    { required: true, message: '请输入验证码', trigger: 'blur' },
    { trigger: 'blur', validator: code }
  ],
  tel: [
    { required: true, message: '请输入账号', trigger: 'blur' },
    { trigger: 'blur', validator: username }
  ]
}

export const rulesforget = {
  phone: [
    { required: true, message: '请输入账号', trigger: 'blur' },
    { trigger: 'blur', validator: username }
  ],
  code: [
    { required: true, message: '请输入验证码', trigger: 'blur' },
    { trigger: 'blur', validator: code }
  ],
  password: [
    { required: true, message: '请输入密码', trigger: 'blur' },
    { trigger: 'blur', validator: password }
  ],
  againpwd: [
    { required: true, message: '请输入密码', trigger: 'blur' },
    { trigger: 'blur', validator: password2 }
  ]
}
// ********************************************************产品的表单********************************************************

let proTime=[
  {  message: '请选择日期', trigger: 'change',required:true },
]

let changePro=(message,trigger='blur')=>{
  return [
    { required: true, message, trigger },
  ]
}

export const ruleOC={
  sale_name:changePro("请输入名称"),
  sale_auth:changePro("请选择权限",'change'),
  sale_style:changePro("请选择风格",'change'),
  sale_type:changePro("请选择类型",'change'),
  sale_money:changePro("请输入金额"),
  sale_lowestmoney:changePro("请输入最低价"),
  sale_lowestadd:changePro("请输入最低加价"),
  sale_endtime:proTime,
  sale_desc:changePro("请输入描述")
}

export const ruleMaterial={
  format:changePro("请选择格式",'change'),
  region:changePro("请选择类型",'change'),
  label:changePro("请选择标签",'change'),
  title:changePro("请输入名称"),
  money:changePro("请输入金额"),
}

// **********************************************稿位***************************************

export const ruleSheetSite={
  sheet_site_name:changePro("请输入稿位名称",'change'),
  sheet_site_size:changePro("请选择稿件大小",'change'),
  sheet_site_precosop:changePro("请选择稿件精度",'change'),
  sheet_site_format:changePro("请选择稿件格式", 'change'),
  sheet_site_type:changePro("请选择出售类型", 'change'),
  sheet_site_auth: changePro('请选择使用权限', 'change'),
  inventory: changePro('请输入稿件库存', 'change'),
  sheet_site_money: changePro('请输入出售金额', 'change'),
  sheet_site_style: changePro('请选择稿位风格', 'change'),
  sheet_site_endtime: changePro('选择截止日期', 'change'),
  sheet_site_desc: changePro('请尽可能说明', 'change'),
  reason: changePro('请说明终止原因', 'change'),
  title:changePro('请输入稿件名称'),
  desc:changePro('请输入稿件说明'),
  img:changePro('请输入稿件图片'),
  dropbox_url:changePro('请输入网盘地址'),
  dropbox_vcode:changePro('请输入提取码'),
}
