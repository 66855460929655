// import {login} from '@/api/login'
import { getUserInfo } from '@/api/user'
import { Message } from 'element-ui'

const user = {
  state: () => ({
    token:"",
    userAllInfo:"",
    id:null//用户Id获取用户信息的
  }),

  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_ALLUSERINFO:(state, userAllInfo)=>{
      state.userAllInfo = userAllInfo
    },
    SET_ID:(state, id)=>{
      state.id = id
    },
    GET_TOKEN:(state)=>{
      state.token = localStorage.getItem('token')
    }


  },

  actions: {
    // 登录
    // Login({commit}, userInfo) {
    //     const username = userInfo.username.trim()
    //     const password = userInfo.password
    //     const code = userInfo.code
    //     const uuid = userInfo.uuid
    //     // return new Promise((resolve, reject) => {
    //     //     login(username, password, code, uuid).then(res => {
    //     //         res = res.data;
    //     //         setToken(res.token)
    //     //         commit('SET_TOKEN', res.token)
    //     //         resolve()
    //     //     }).catch(error => {
    //     //         reject(error)
    //     //     })
    //     // })
    // },
    async getUserInfo({ commit }) {
      try {
        const token = localStorage.getItem('token')

        //  登录之后只返回token和tongfanstate，并没有id需要重新存储id
        const res = await getUserInfo(token)
        if (res.id) {
          localStorage.setItem('id', res.id)
        }
        if (!token || !res.id ){
          console.log("可以退出了")
          return 
        }
        commit('SET_TOKEN',token)
        commit('SET_ID',res.id)
        commit('SET_ALLUSERINFO',res)
      }catch (e) {
        Message({message: "服务器出错请刷新重试" , type: 'error'})
      }
    }
  }
}

export default user
