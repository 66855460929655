import Vue from 'vue'

import { MessageBox  } from 'element-ui'
Vue.directive('dangOption',{
    bind(el,binding){
        el.addEventListener('click',()=>{
            MessageBox.confirm(
                '下架会直接将商品删除，请谨慎操作',
                '警告',
                {
                    confirmButtonText:"确认",
                    cancelButtonText:"取消",
                    type:"warning"
                }
            ).then(_=>{
                binding.value.resolve()
            })
                .catch(_=>{
                    // MessageBox.alert("取消成功")
                })
        })
    }
})
