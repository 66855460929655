<template>
  <el-container>
    <el-header class="header">
      <div>
        <img src="../../assets/images/logo.png" style="width: 50px;height: 50px">
      </div>
      <div class="center main-font-color">橱窗后台</div>
      <div>
        <el-dropdown trigger="click" @command="handleCommand">
          <el-avatar class="hand"  :src="userinfoImg" @error="()=>true" >
            <img src="https://cube.elemecdn.com/e/fd/0fc7d20532fdaf769a25683617711png.png"/>
          </el-avatar>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="back">退出</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>

      </div>
    </el-header>
    <el-container style="background-color: #fbfaff">
      <el-aside width="200px" class="aside" :style="{minHeight:aside.height }">
<!--        <ul>-->
<!--          <li v-for="(item,index) in asideList" :key="index">-->
<!--            {{'/home'+item.path}}-->
<!--            <router-link :to="'/home'+item.path">{{item.name}}</router-link>-->
<!--          </li>-->
<!--        </ul>-->
        <el-menu
            :unique-opened="true"
            class="el-menu-vertical-demo"
            router
            @open="handleOpen"
            @close="handleClose">
<!--          <el-menu-item index="/home">-->
<!--            <div class="menu">-->
<!--                <i class="el-icon-location"></i>-->
<!--                <span>首页</span>-->
<!--            </div>-->
<!--          </el-menu-item>-->
          <el-submenu  v-for="(item,index) in asideList" :index="(index+2).toString()" :key="index">
            <template slot="title">
              <div class="menu">
                <img :src="item.icon">
                <span slot="title">{{item.name}}</span>
              </div>
<!--              <i class="el-icon-location"></i>-->
<!--              <span>{{item.name}}</span>-->
            </template>
            <template v-if="item.list">
              <el-menu-item :index="'/home'+child.path" v-for="child in item.list" :key="child.path" :disabled="child.disa">{{child.name}}</el-menu-item>
            </template>
          </el-submenu>
          <el-menu-item :index="'/home'+item.path" v-for="item in itemList" :key="item.path" v-if="tongfanstate === 2">
            <div class="menu">
              <img :src="item.icon">
              <span slot="title">{{item.name}}</span>
            </div>
          </el-menu-item>
        </el-menu>
      </el-aside>
      <el-main  style="background-color: #fbfaff">
        <router-view/>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import {mapGetters} from "vuex"
import { getWorks } from '@/api/user'
export default {
  name: "HomeIndexPage",
  components: {},
  data() {
    return {
      userinfoImg:"https://images.chuoooo.cn/paint/20230703/f830185a174068121e006670ffc45223.jpg",
      aside:{
        height:window.innerHeight-60+'px'
      },
      asideList: [],
      itemList:[
        {
          name:"订单",
          path: "/order",
          icon:require('@/assets/images/aside/order.png')
        },
        {
          name:"账户",
          path:"/account",
          icon:require('@/assets/images/aside/account.png')
        }
      ],
      tongfanstate: localStorage.getItem('tongfanstate')
    }
  },
  computed:{
    ...mapGetters(['userAllInfo'])
  },                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          

  async mounted() {
    this.asideList = await this.returnAllList()
    this.asideList.push(
      {
        name:"我的",
        icon:require('@/assets/images/aside/account.png'),
        list:[
          ...this.itemList
        ]
      },  
    )
  },
  watch:{
    userAllInfo:{
      handler(n){
        console.log(n)
        this.userinfoImg=n?.icon?.[0]
      },
      immediate:true
    }

  },


  methods: {
    // 获取用户是否已经上传10张作品
    async isUpdateWorks () {
      try{
        const params = {
          nid: 2,
          token: localStorage.getItem('token')
        }
        const res = await getWorks({params})
        return res.total
      }catch (e) {
        return this.$message.error(e.message)
      }
    },

    // 筛选 侧边栏进行灰度处理
     async returnAllList() {
      const asideList = [
            {
              name:"设子",
              icon:require('@/assets/images/aside/oc.png'),
              list:[
                {
                  name:"出设",
                  path:"/salesPro"
                },
                {
                  name:"列表",
                  path:"/saleList"
                }
              ]
            },
            {
              name:"VUP定制",
              icon:require('@/assets/images/aside/vup.png'),
              list:[
                {
                  name:"虚拟形象",
                  path:"/saleVirtually"
                },
                {
                  name:"动捕设备",
                  path:"/saleMobileCapture",
                  disa:true
                }
              ]
            },
            {
              name:"素材",
              icon:require('@/assets/images/aside/material.png'),
              list:[
                {
                  name:"设置",
                  path:"/saleMaterial"
                },
                {
                  name:"列表",
                  path:"/saleMaterialList"
                }
              ]
            },
            {
              name:"稿位",
              icon:require('@/assets/images/aside/sheet-site.png'),
              list:[
                {
                  name:"设置",
                  path:"/sheetSiteSet"
                },
                {
                  name:"列表",
                  path:"/sheetSiteList"
                }
              ]
            },
          ]
      // 提示信息
      const tongfanstateMsg = {
        0: '橱窗后台未全部开通, 请前往前台进行橱窗申请，仅开放设子功能', // 仅设子
        1: '资料正在审核中，仅开启部分权限，仅开放设子功能', // 仅设子
        2: '审核已通过，已开通全部权限', 
        3: '审核已通过，但由于您上传的作品并未到达10张，稿位功能暂时无法开启' // 稿位不开启
      }
      let allList = []
      let tongfanstate = this.tongfanstate
      if (this.tongfanstate == 0 || this.tongfanstate == 1) {
        allList = asideList.filter(item => item.name === '设子')
      } 
      else if(this.tongfanstate == 2) {
        // 资料审核通过判断是否发表过10张作品 是则开启稿位权限 否则关闭稿位权限
        // const workTotal = await this.isUpdateWorks()
        // if (workTotal < 10) {
        //   // 稿位
        //   tongfanstate = 3
        //   const skectIndex = asideList.findIndex(item => item.name === '稿位')
        //   asideList.splice(skectIndex, 1)
        // }
        allList = asideList
      }
      this.$message({
        type: 'info',
        message: tongfanstateMsg[tongfanstate],
        duration: 800
      })
      // console.log(asideList, 'asideList')
      return allList
    },


    handleOpen(index){
      console.log(index)
    },
    handleClose(index){
      console.log(index)

    },
    handleCommand(command){
      localStorage.clear()
      this.$store.commit('user/SET_TOKEN')
      this.$store.dispatch('user/getUserInfo')
      this.$message.success("退出成功")
      this.$router.push('/login')
    }
  },

}
</script>

<style scoped lang="scss">
.header{
  display: flex;
  gap: 10px;
  >div{
    flex: 1;
    //background-color: #6357ff;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .center{
    flex: 5;
  }
}
.aside{
  //background-color: #6f42c1;
  background-color: #fbfaff;
}
.menu>img{
  margin-right: 10px;
}
::v-deep .el-menu{
  margin-top: 20px;
  background-color: #fff;
  border-radius: 30px;
  border: none;
}

::v-deep .el-menu-item.is-active{
  color: #303133;

}
</style>
