import {pageLoadingHttps,otherService,ButLoadingHttps} from "@/utils/request";

export const getUserInfo=(token)=>otherService.get("/login/getmyinfo",{params:{token}})

export const getOrder=(params)=>pageLoadingHttps.get("/homeorder/orderlist",{params})
export const getUserMoneyList=(params)=>pageLoadingHttps.get("/homeorder/ordermoneylist",{params})

export const orderMoneyAdd= (data)=>ButLoadingHttps.post("/homeorder/ordermoneyadd",data)// 提现

export const getAccountInfo=(params)=>otherService.get("/userinfo/recharge",{params})

export const editPersonAcu= (data)=>ButLoadingHttps.post("/homeuser/updateuser",data)// 修改个人账户的信息
export const editBusAcu= (data)=>ButLoadingHttps.post("/homeuser/updateenter",data)// 修改企业账户的信息

export const getWorks = (params)=> otherService.get('/personal/mywork', params) // 获取用户作品信息

