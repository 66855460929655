<template>
  <el-form :model="phoneLoginform"  ref="ruleLoginPwdForm" :rules="rules1" class="phoneLogin">
    <el-form-item prop="phone">
      <el-input
          placeholder="请输入手机号"
          v-model.trim="phoneLoginform.phone"
          :disabled="disabled"
      >
      </el-input>
    </el-form-item>
    <el-form-item prop="code" class="getCode">
      <el-input
          placeholder="请输入验证码"
          v-model="phoneLoginform.code"

      > <el-button slot="suffix" :disabled="disabled" @click="getCode">
        <span >{{codeText}}</span>
      </el-button>
      </el-input>
    </el-form-item>

    <el-form-item class="loogin-button">
      <el-button @click.native="judge" :loading="loginloading">登录</el-button>
    </el-form-item>
  </el-form>
</template>

<script>
import {rules1} from "@/utils/verify/rules"
import {phoneCode,phoneLogin} from "@/api/Login"
export default {
  name: "phoneLogin",
  computed: {
    rules1() {
      return rules1
    }
  },
  data() {
    return {
      //验证码登陆
      phoneLoginform:{
        phone:"",
        code:""
      },
      codeText:"获取验证码",
      time:60,
      disabled:false,
      loginloading:false
    }
  },
  created() {
    document.onkeyup=e=>{
      if (e.keyCode === 13){
        this.judge()
      }
    }
  },
  methods: {
    getCode(){
     this.$refs.ruleLoginPwdForm.validateField('phone',valid=>{
       console.log(valid,"这是什么")
       if (!valid){
         this.getPhonCode()
       }

     })
    },
    async getPhonCode(){
      let res = await phoneCode({tel:this.phoneLoginform.phone})
      if (res.msg === '发送成功'){
        this.$message.success(res.msg)
        this.timeOut()
      }else {
        this.$message.error("发送失败请重试")
      }

    },
    timeOut(){
      if (this.time > 0){
        this.disabled=true
        this.time--
        this.codeText=this.time + 's秒后重新获取';
        setTimeout(this.timeOut,1000)
      }else {
        this.time=0;
        this.codeText='获取验证码';
        this.disabled=false
      }
    },
    judge(){
      this.$refs.ruleLoginPwdForm.validate(valid=>{
        if (valid){
            this.login()
        }
      })
    },
    async login(){
      this.loginloading = true
      let params = {
        tel:this.phoneLoginform.phone,
        code:this.phoneLoginform.code
      }
      let res = await phoneLogin(params)
      this.loginloading = false
      console.log(res,"登陆是东西")
      // if (res.code !==1) return this.$message.error(res.msg)

      // if (res.tongfanstate === 0) return this.$message.error("未认证开通橱窗，请前往通贩进行开通")
      // if (res.tongfanstate === 1) return this.$message.error("正在审核中~")
      // localStorage.setItem('id',res.id);
      if (!res.tongfanstate && res.tongfanstate !== 0) return this.$message.error(res.msg)
      localStorage.setItem('token',res.token);
      localStorage.setItem('tongfanstate',res.tongfanstate);
      this.$message.success("登陆成功")
      this.$router.push("/home/salesPro")
      this.$store.dispatch('user/getUserInfo')
    },

  }

}
</script>

<style scoped lang="scss">
::v-deep.el-input {
  width: 260px;
  height: 44px;
}

::v-deep.el-button {
  width: 94px;
  padding: 0;
  border: none;
  font-family: 'PingFang HK';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #b5b4cc;
}
.phoneLogin ::v-deep  .el-input__inner{
  border-radius: 12px;
  height: 44px;
  line-height: 44px;
  background-color: rgba(220,220,242,.4);
  border: none;
  padding-left: 15px;
}
::v-deep .el-input{
  font-size: 15px;
}
::v-deep .el-input__icon{
  line-height: 44px;
}
::v-deep .el-input__prefix{
  font-size: 20px;
  color: #242433;
}
::v-deep .el-form-item__error{
  left: 40%;
}
::v-deep .is-error .el-form-item__content .el-input{
  border: 1px solid #fe1d5f;
  border-radius: 12px;
}
.el-form {
  .loogin-button .el-button {
    width: 260px;
    height: 44px;
    background: #6357ff;
    border-radius: 12px;
    font-family: 'PingFang HK';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #ffffff;
  }
  .getCode .el-button {
    border: 0;
    background-color: transparent;
    height: 100%;
    width: auto;
  }

  }
</style>
